import React from 'react';

class AboutUs extends React.Component {
	render() {
		return (
			<div id="about-us">
				About Us
			</div>
		);
	}
}
export default AboutUs;