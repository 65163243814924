import React from 'react';

class Team extends React.Component {
	render() {
		return (
			<div id="team">
				Team
			</div>
		);
	}
}
export default Team;