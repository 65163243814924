import React from "react";

import GallerySection from "./gallery-section";

class CalendarSchedule extends React.Component {
  render() {
    return (
      <div id="calendar-schedule">
        <GallerySection />

        <div id="parkland-block" className="parkland-box-holder">
          <h2>Parkland Calendar & Schedule</h2>
          <div className="parkland-box">
            <div className="image-holder">
              <a href="https://storage.googleapis.com/ema-calendars/parkland_calendar.pdf" target="_blank" rel="noreferrer">
                <img src="https://storage.googleapis.com/ema-calendars/parkland_calendar.webp" alt="enlargeC" />
              </a>
            </div>
            <h4>
              <a href="https://storage.googleapis.com/ema-calendars/parkland_calendar.pdf" target="_blank" rel="noreferrer">
                Enlarge Calendar
              </a>
            </h4>
          </div>
          <div className="parkland-box">
            <div className="image-holder">
              <a href="https://storage.googleapis.com/ema-calendars/parkland_schedule.pdf" target="_blank" rel="noreferrer">
                <img src="https://storage.googleapis.com/ema-calendars/parkland_schedule.webp" alt="enlargeC" />
              </a>
            </div>
            <h4>
              <a href="https://storage.googleapis.com/ema-calendars/parkland_schedule.pdf" target="blank" rel="noreferrer">
                Enlarge Schedule
              </a>
            </h4>
          </div>
        </div>

        <div id="sunrise-block" className="parkland-box-holder">
          <h2>Sunrise Calendar & Schedule</h2>
          <div className="parkland-box">
            <div className="image-holder">
              <a href="https://storage.googleapis.com/ema-calendars/sunrise_calendar.pdf" target="_blank" rel="noreferrer">
                <img src="https://storage.googleapis.com/ema-calendars/sunrise_calendar.webp" alt="enlargeC" />
              </a>
            </div>
            <h4>
              <a href="https://storage.googleapis.com/ema-calendars/sunrise_calendar.pdf" target="_blank" rel="noreferrer">
                Enlarge Calendar
              </a>
            </h4>
          </div>
          <div className="parkland-box">
            <div className="image-holder">
              <a href="https://storage.googleapis.com/ema-calendars/sunrise_schedule.pdf" target="_blank" rel="noreferrer">
                <img src="https://storage.googleapis.com/ema-calendars/sunrise_schedule.webp" alt="enlargeC" />
              </a>
            </div>
            <h4>
              <a href="https://storage.googleapis.com/ema-calendars/sunrise_schedule.pdf" target="_blank" rel="noreferrer">
                Enlarge Schedule
              </a>
            </h4>
          </div>
        </div>
      </div>
    );
  }
}
export default CalendarSchedule;
