import React from "react";
import ReactTooltip from "react-tooltip";
//import { meetList,assistanInstructorsList, officeList } from "./staff-data";
import meetIcon3 from "../../../images/m3.webp";
import meetIcon4 from "../../../images/m4.webp";
import meetIcon7 from "../../../images/m7.webp";
import meetIcon8 from "../../../images/m8.webp";
import meetIcon9 from "../../../images/pedro-mantis.webp";
import meetIcon15 from "../../../images/m15.webp";
import andresMeetIcon from "../../../images/andres-bustamante-standing.webp";

const meetList = [
  {
    meetIcon: meetIcon3,
    meetTitle: "MR. Rosen",
    meetDesignation: "Head Instructor, Parkland",
    info: "My name is Steven Rosen, and I am the head instructor at Evolution Martial Arts in Parkland. I have been training in the Mix Martial Arts for over 16 years, and I am currently a fourth-degree black belt. Through the years of experience, I have achieved tournament champion in sparring and forms in South Florida. Specializing in weapons, sparring, self-defense, and various styles such as Taekwondo, Thai boxing, Jiu-jitsu, and Kung fu; I strive to continue the lifelong dedication in the field of martial arts to bring discipline, perseverance, morals, and inspiration to all my students as they continue forward with their journey.",
  },
  {
    meetIcon: meetIcon7,
    meetTitle: "MR. Oscar Washington",
    meetDesignation: "Head Instructor, Sunrise",
    info: "My name is Oscar Washington. I am a 4th degree black belt and the head instructor at the Sunrise location. I have been training at Evolution Martial Arts for 12 years and have been teaching for 5 years.  I started martial arts because I was bullied growing up;  martial arts gave me the confidence and courage to stand up to those bullies.   So the reason I still train and even teach now is to set an example to other students who might be going through the same challenges that I did to show them how much martial arts could impact your life.",
  },
  {
    meetIcon: meetIcon4,
    meetTitle: "MS. Kristy Roth",
    meetDesignation: "Instructor",
  },
  {
    meetIcon: andresMeetIcon,
    meetTitle: "MR. Bustamante",
    meetDesignation: "Instructor",
    info: "My name is Andres Bustamante. I’m a 1st Degree Black Belt and Instructor at Evolution Martial Arts. I have been training for about 5 years. Specializing in numerous styles like Taekwondo, Kung-Fu, Karate, Muay Thai, Israeili Krav Maga, weapons and more. I am also experienced in the Iaido Japanese sword martial art. I’m excited to bring more to the table as I continue to learn new things. My mission is to not only push myself and my student’s abilities foward but to peak my student’s interests in the world of martial arts and teach them tradition, honor, discipline, perseverance and much more as they advance through their quest through martial arts.",
  },
  {
    meetIcon: meetIcon15,
    meetTitle: "MR. Jimenez",
    meetDesignation: "Instructor",
    info: "My name is Nick Jimenez, and I am currently a 2nd Degree Black Belt. I have been training for 13 years which includes my time teaching here. I have done my best the entire time to make sure that I help the students learn everything they need for our classes and the real world. My goal is to make sure that everyone I have taught could use their skills as they continue to grow. My purpose for teaching is not only because I have been doing it for so long, but to also encourage others about everything I have learned and what they can learn from sticking to what they believe in.",
  },
  {
    meetIcon: meetIcon9,
    meetTitle: "MR. Valladares",
    meetDesignation: "Master Instructor",
    info: "As a lifelong martial artist, my passion was sparked in the late 1980s. I was captivated by the incredible speed, power, coordination, and knowledge exhibited by experienced practitioners. This inspired me to fully immerse myself in this art form, setting me on a dedicated path of acquiring and preserving knowledge. Now it is my responsibility to pass down this wisdom to the instructors and students at Evolution Martial Arts (EMA), ensuring the continued vitality of this ancient discipline. I firmly believe that martial arts can bring joy, discipline, focus, and physical well-being to all who practice it. At EMA, I feel deeply honored to contribute to the transmission of this art form. Through careful instruction and mentorship, we empower our instructors and students with the necessary skills to carry on this revered tradition. Our focus extends beyond technical prowess; we also strive to develop character, fortitude, and mental resilience. Witnessing our students embrace the discipline and embark on their own paths brings me profound joy. We witness firsthand how martial arts can bring about transformative changes in their lives, fostering personal growth and self-discovery. With unwavering dedication, I commit myself to nurturing the growth and evolution of martial arts within the EMA community. Together, we forge a legacy that resonates through generations, elevating the lives of all who seek solace, enlightenment, and personal development through the timeless practice of this art"
  },
];

/* const assistanInstructorsList = [{
  meetIcon: meetIcon5,
  meetTitle: "MR. Eli Roth",
  meetDesignation: "BBLP Assistant Instructor",
  info: "My name is Eli Roth, I have been training for about 7 years I am 11 years old and I’m in sixth grade and I am in the demonstration team and a junior instructor.",
}, 
      meetIcon: meetIcon6,
    meetTitle: "MR. Victor Patino",
    meetDesignation: "BBLP Assistant Instructor",
    info: "My name is Victor Patino, I am 15 years old and in the 10th grade. I have been training at Evolution Martial Arts for approximately 5 years. I am a brown belt. I am a member of the demonstration team, and a junior instructor.",
  },
 {
    meetIcon: meetIcon10,
    meetTitle: "MR. Enzo Aguiar",
    meetDesignation: "BBLP Assistant Instructor",
    info: "My name is Enzo Aguiar, and I am currently a brown belt. I have been training in Mixed Martial Arts for three years, and my dream is to achieve my black belt within the next two years. My goal is to become an instructor at Evolution Martial Arts and share my skills with the students so that if they get into any harmful situation, they will know how to defend themselves and their loved ones.",
  }, 

]; */

const officeList = [
  {
    meetIcon: meetIcon8,
    meetTitle: "MRS. Monica",
    meetDesignation: "Office Master, Sunrise",
    info: "Originally from New York, I have been residing in Florida for the past 27 years. As a dedicated mother of four wonderful children, I have always prioritized their growth and development. It was through my son's six-year journey at Evolution Martial Arts that I became acquainted with this remarkable community.For the past six years, I have had the privilege of actively supporting the endeavors of Evolution Martial Arts. Witnessing the positive impact of martial arts education on my own children, as well as numerous students, has inspired my deep appreciation for this valuable discipline. My role lies in fostering an environment where children can flourish and learn through martial arts. It brings me great satisfaction to contribute to their growth, character development, and self-confidence as they navigate their unique martial arts journeys. Evolution Martial Arts has become an integral part of my life, and I take immense pride in being part of an organization that empowers individuals through martial arts education. I am committed to supporting and guiding young minds as they discover the transformative benefits of this ancient art form.",
  },
];
class MeetSection extends React.Component {
  render() {
    return (
      <section id="meet-section" className="meet-section">
        <ReactTooltip className="tooltip" />
        <div className="meet-text">
          <h2>Meet Our Instructors</h2>
          <article>
            <p>
              Our staff is dedicated to excellence and to serving each student's
              individual goals. Evolution Martial Arts' instructors believe in
              being their best so that our students can be their best.
            </p>
          </article>
        </div>
        <div className="container">
          <ul>
            {meetList.map((meetItem) => (
              <li
                key={meetItem.meetTitle}
                data-tip={meetItem.info}
                id={meetItem.meetTitle}
              >
                <div className="meet-box instructor-image">
                  <img src={meetItem.meetIcon} alt={meetItem.meetTitle} />
                  <div className="meet-box-details">
                    <h3>{meetItem.meetTitle}</h3>
                    <p>{meetItem.meetDesignation}</p>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </div>
        <div className="meet-text">
          <h2>Meet Our Office Staff</h2>
        </div>
        <div className="container">
          <ul>
            {officeList.map((meetItem) => (
              <li
                key={meetItem.meetTitle}
                data-tip={meetItem.info}
                id={meetItem.meetTitle}
              >
                <div className="meet-box">
                  <img src={meetItem.meetIcon} alt={meetItem.meetTitle} />
                  <div className="meet-box-details">
                    <h3>{meetItem.meetTitle}</h3>
                    <p>{meetItem.meetDesignation}</p>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </section>
    );
  }
}
export default MeetSection;
