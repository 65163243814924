import React from "react";

import trainingIcon4 from "../../../images/Grappling@2x.webp";

import { IoMdCloseCircleOutline } from "react-icons/io";

class Grappling extends React.Component {
  hide = (event) => {
    const { onClose } = this.props;
    if (onClose) {
      onClose();
    }
    event.target.parentNode.closest(".Popup").classList.add("active");
    event.target.parentNode.closest("button").classList.add("active");
  };
  render() {
    return (
      <section id="training-box-section" className="training-box-section">
        <div className="Popup">
          <button onClick={this.hide} className="popup-close-button">
            <IoMdCloseCircleOutline />
          </button>
          <React.Fragment>
            <div className="popup-image-holder">
              <img src={trainingIcon4} alt="Grappling" />
            </div>
            <div className="popup-content-holder">
              <h2 style={{ borderBottom: "none" }}>Grappling</h2>
              <p style={{ color: "#000" }}>
                Evolution Martial Arts' grappling programs provide a complete
                ground fighting experience.
              </p>
              <p style={{ color: "#000" }}>
                Our program combines the takedowns of judo, the reversals of wrestling and
                the submissions of Jujitsu and Russian Sambo.
              </p>
              <p style={{ color: "#000" }}>
                Program participants enjoy an amazing workout while learning effective
                self defense against any ground fighting situation.
              </p>
            </div>
          </React.Fragment>
        </div>
      </section>
    );
  }
}
export default Grappling;
