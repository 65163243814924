import React from 'react';

class Members extends React.Component {
	render() {
		return (
			<div id="members">
				Members
			</div>
		);
	}
}
export default Members;