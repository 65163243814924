import React from 'react';

class Error extends React.Component {
	render() {
		return (
			<div id="error">
				Error 404
			</div>
		);
	}
}
export default Error;