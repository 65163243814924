import React from 'react';

class ContactUs extends React.Component {
	render() {
		return (
			<div id="contact-us">
				Contact Us
			</div>
		);
	}
}
export default ContactUs;