import React from 'react';

class Program extends React.Component {
	render() {
		return (
			<div id="program">
				Program
			</div>
		);
	}
}
export default Program;